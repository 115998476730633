import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="chat"
export default class extends Controller {
  static targets = ["prompt", "conversation"]

  connect() {
    console.log('[ openai:chat ] Connect');
  }

  disconnect() {
    console.log('[ openai:chat ] Disconnect');

    if (this.eventSource) {
      this.eventSource.close();
    }
  }

  generateResponse(event) {
    event.preventDefault();

    this.#createAuthor('You', true);
    this.#createMessage(this.promptTarget.value, true);
    this.#createAuthor('Assistent');

    this.currentPre = this.#createMessage('');

    this.#setupEventSource();

    this.promptTarget.value = "";
  }

  #createAuthor(text, you = false) {
    const container = document.createElement('div');

    if (you) {
      container.classList.add('chat-message-author-container-you');
    } else {
      container.classList.add('chat-message-author-container');
    }

    const content = document.createElement('p');
    content.classList.add('chat-message-author-content');
    content.innerHTML = `${text}`;

    container.appendChild(content);

    this.conversationTarget.appendChild(container);
  }

  #createMessage(text, you = false) {
    const container = document.createElement('div');

    if (you) {
      container.classList.add('chat-message-message-container-you');
    } else {
      container.classList.add('chat-message-message-container');
    }

    const content = document.createElement('p');
    content.classList.add('chat-message-message-content');
    content.innerHTML = `${text}`;

    container.appendChild(content);

    this.conversationTarget.appendChild(container);

    return content
  }

  #setupEventSource() {
    this.eventSource = new EventSource(`/lab/openai/chat_responses?prompt=${this.promptTarget.value}`);
    this.eventSource.addEventListener('message', this.#handleMessage.bind(this));
    this.eventSource.addEventListener('error', this.#handleError.bind(this));
  }

  #handleMessage(event) {
    const parsedData = JSON.parse(event.data);


    this.currentPre.innerHTML += parsedData.message;

    // scroll to bottom of the conversation
    this.conversationTarget.scrollTop = this.conversationTarget.scrollHeight;
  }

  #handleError(event) {
    if (event.eventPhase == EventSource.CLOSED) {
      this.eventSource.close();
    }
  }
}
