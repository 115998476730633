import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "form", "input", "searchIcon", "searchButton", "clearIcon" ]
  static classes = [ "clearIconVisibility", "inputPadding", "searchIconVisibility" ]

  connect() {
    this.clearIconTarget.classList.add(this.clearIconVisibilityClass)
    this.searchButtonTarget.classList.add(this.searchIconVisibilityClass)
  }

  submit() {
    this.inputTarget.blur()
    this.clear()
  }

  submitForm() {
    this.formTarget.submit()
  }

  valueChange() {
    const inputLength = this.inputTarget.value.length
    this.inputTarget.classList.toggle(this.inputPaddingClass, inputLength > 0)
    this.searchIconTarget.classList.toggle(this.searchIconVisibilityClass, inputLength > 0)
    this.clearIconTarget.classList.toggle(this.clearIconVisibilityClass, inputLength == 0)
    this.searchButtonTarget.classList.toggle(this.searchIconVisibilityClass, inputLength == 0)
  }

  clear() {
    this.inputTarget.value = ""
    this.clearIconTarget.classList.add(this.clearIconVisibilityClass)
    this.searchIconTarget.classList.remove(...this.searchIconVisibilityClasses)
    this.inputTarget.classList.remove(this.inputPaddingClass)
    this.searchButtonTarget.classList.add(this.searchIconVisibilityClass)
  }
}
