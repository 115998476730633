import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dropdownMenu"]
  static classes = ["visibility"]

  connect() {
    this.dropdownMenuTarget.classList.add(this.visibilityClass)

    document.addEventListener(
      "keydown",
      ({ key }) =>
        key === "Escape" &&
        this.dropdownMenuTarget.classList.add(this.visibilityClass)
    )

    document.addEventListener("click", (event) => {
      const isDropdownElement = event.target.closest(
        "[data-controller=dropdown]"
      )
      !isDropdownElement &&
        this.dropdownMenuTarget.classList.add(this.visibilityClass)
    })
  }

  toggle() {
    this.dropdownMenuTarget.classList.toggle(this.visibilityClass)
  }
}
